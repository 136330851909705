import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage';
import UserManagementPage from './UserManagementPage';
import FunctionSelectorPage from './FunctionSelectorPage';
import FranceSlotGetPage from './FranceSlotGetPage';
import TurnitinUI from './TurnitinUI.js';
import BookStatus from './BookStatus.js';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/user-management" element={<UserManagementPage />} />
        <Route path="/function-selector" element={<FunctionSelectorPage />} />
        <Route path="/france-slot-get" element={<FranceSlotGetPage />} />
        <Route path="/turnitin-ui" element={<TurnitinUI />} />
        <Route path="/book-status" element={<BookStatus />} />
        {/* 添加其他路由 */}
      </Routes>
    </Router>
  );
};

export default App;
