import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

// 定义ChargeType枚举
const ChargeType = {
  PER_USAGE: 'P',
  PER_DAY: 'D'
};

// 密钥管理器组件
function KeyManager() {
  // 状态定义
  const [includesAi, setIncludesAi] = useState(true);
  const [chargeType, setChargeType] = useState(ChargeType.PER_USAGE);
  const [maxUsage, setMaxUsage] = useState(0);
  const [key, setKey] = useState('');
  // 添加tid状态
  const [tid, setTid] = useState('');


  // 生成随机字符串
  const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  const postKeyToServer = (apiKey) => {
    const url = 'http://92.119.127.236:8080/turnitin/api/users'; // 服务器接口地址
    const token = localStorage.getItem('token'); // 从本地存储中获取token
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({
        apiKey: apiKey,
        tid: tid, // 假设tid是已知的，或者有其他方式生成
        calltimes: 0,
      }),
    };
  
    fetch(url, options)
    .then(response => {
      if (response.ok || response.status === 201) {
        return response.json(); // 解析JSON
      }
      else {
        return response.text()
      }
    })
    .then(data => {
      if(typeof data === 'object' && data != null){
        // 只有当请求成功时，才弹出提示并更新key
        alert('Key created successfully.' || data.apiKey);
        // 使用data中的apiKey，如果data中没有apiKey，则保持不变
        setKey(data.apiKey || key);
      }
      else{ 
        alert('Failed to create key: ' + data);
      }
        
    })
    .catch((error) => {
      console.error('Error:', error);
      // 请求失败时，弹出错误提示，但不更新key
      //alert('Failed to create key: ' + error.message);
    });
};  

  // 生成密钥
  const generateKey = () => {
    const timestamp = Math.floor(Date.now() / 1000); // 获取当前时间戳
    const aiFlag = includesAi ? 'A' : 'N';
    const maxUsageStr = maxUsage.toString().padStart(3, '0');
    const randomStrLength = chargeType === ChargeType.PER_USAGE ? 32 : 48;
    const randomStr = generateRandomString(randomStrLength);

    const key = `${chargeType}${aiFlag}${timestamp.toString().padStart(10, '0')}${maxUsageStr}${randomStr}`;
    postKeyToServer(key);
  };


  return (
    <div className="container mt-5"> {/* 上边距调整 */}
      <h2>Key Generator</h2>
      <form className="mb-3"> {/* 下边距调整 */}
        <div className="form-check mb-3"> {/* 下边距调整 */}
          <input
            className="form-check-input"
            type="checkbox"
            value={includesAi}
            onChange={(e) => setIncludesAi(e.target.checked)}
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            Include AI
          </label>
        </div>
        <div className="mb-3"> {/* 下边距调整 */}
          <label htmlFor="chargeTypeSelect" className="form-label">Charge Type</label>
          <select
            id="chargeTypeSelect"
            className="form-select"
            value={chargeType}
            onChange={(e) => setChargeType(e.target.value)}
          >
            <option value="P">Per Usage</option>
            <option value="D">Per Day</option>
          </select>
        </div>
        <div className="mb-3"> {/* 下边距调整 */}
          <label htmlFor="maxUsageInput" className="form-label">Max Usage</label>
          <input
            type="number"
            className="form-control"
            id="maxUsageInput"
            value={maxUsage}
            onChange={(e) => setMaxUsage(e.target.value)}
          />
        </div>
        <div className="mb-3"> {/* 下边距调整 */}
          <label htmlFor="tidInput" className="form-label">Transaction ID (tid)</label>
          <input
            type="text"
            className="form-control"
            id="tidInput"
            value={tid}
            onChange={(e) => setTid(e.target.value)}
          />
        </div>
        <button type="button" onClick={generateKey} className="btn btn-primary mb-3">Generate Key</button> {/* 下边距调整 */}
      </form>
      {key && (
    <div className="alert alert-success mt-3">
      <strong>Copy the link and open it in your browser:</strong><br />
      <strong>turnitins.vip</strong><br />
      <strong>密钥: {key}</strong>
      {/* 修改点：只有当postKeyToServer请求成功时，才会显示这个strong标签 */}
    </div>
  )}
    </div>
  );
};


export default KeyManager;